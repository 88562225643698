.btn {
  background-color: #f8ce6f;
  border: none;
  width: 180px;
  height: 40px;
  padding: 6px 9px 6px 9px;
  border-radius: 30px;
  color: #ffffff;
  cursor: pointer;
}
.btn-lg {
  width: 250px;
  height: 60px;
  padding: 9px 12px 9px 12px;
  font-size: 20px;
  font-weight: 500;
}

.btn-md {
  width: 210px;
  height: 50px;
  padding: 6px 10px 6px 10px;
  font-size: 18px;
  font-weight: 300;
}

.btn-modal {
  width: 150px;
  height: 50px;
  padding: 6px 10px 6px 10px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 15px;
}

.btn-modal-cancel {
  color: #5b616f;
  background-color: white;
}
.btn-modal-cancel:hover {
  background-color: rgb(185, 184, 184) !important;
}
.btn:hover {
  background-color: #e8c269;
}

@media (max-width: 768px) {
  .btn {
    width: 80px;
    height: 40px;
    padding: 6px 9px 6px 9px;
  }
  .btn-lg{
    width: 90px;
    height: 40px;
    padding: 6px 9px 6px 9px;
    font-size: 14px;
  }
  .btn-main{
    display: none;
  }
  .btn-md{
    width: 90px;
    height: 40px;
    padding: 6px 9px 6px 9px;
    font-size: 14px;
  }
}

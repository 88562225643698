*{
  font-family: 'Poppins', sans-serif;
}

textarea{
  resize: none;
}

.not-login{
  font-size: 40px;
  margin-top: 5rem;
  height: 768px;
}

.title {
  color: #2E3138;
  cursor:default;
  text-decoration: none;
}

.title a{
  color: #2E3138;
  cursor:default;
  text-decoration: none;
}
.container {
  margin: 0 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.header {
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ECF5F2;
  padding: 5px 5rem 5px 5rem;
  font-size: 24px;
  color: #2E3138;
}
.btn-main span{
  display: none;
}
.btn-main {
  display: flex;
  flex-direction: flex-end;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 70%;
}

.card {
  background-color: #ECF5F2;
  width: 75%;
  padding: 1rem;
  border-radius: 25px;
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
  gap: 4rem;
  align-items: flex-start;
}

.card-info h4{
  font-size: 26px;
  font-weight: 400;
  color: #2E3138;
  margin: 0;
  margin-bottom: 0.75rem;
}

.card-info .card-desc{
  font-size: 22px;
  color: #5B616F;
  font-weight: 300;
  margin: 0;  
  margin-bottom: 0.75rem;
  width: 70%;
}

.card-info p{
  text-align: left;
  font-size: 23px;
  color: #2E3138;
  font-weight: 500;
  margin: 0;
  margin-bottom: 0.75rem;
}
.icons-card{
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.icons-card .icon {
  background-color: #F8CE6F;
  padding: 0.5rem;
  border-radius: 15px;
}
.icons-card .icon button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
.icons-card .icon button img{
  filter: brightness(0) invert(1);
  width: 35px;
  height: 35px;
}
.modal {
  position: fixed;
  top: 10vh;
  /* left: 5%; */
  width: 90%;
  background-color: #ECF5F2;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
}
.input-group {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1rem;
}
.input-group label{
  color: #2E3138;
  font-size: 24px;
  font-weight: 400;
}
.input-group input {
  color: #5B616F;
  font-size: 20px;
  border: none;
  padding: 0;
  padding: 20px;
  background-color: white;
  border-radius: 25px;
  font-weight: 300;
}

.input-group textarea{
  border: none;
  color: #5B616F;
  border-radius: 20px;
  padding: 1rem;
  font-size: 20px;
}


.modal-page {
  background-color: #ECF5F2;
  padding: 4rem;
  border-radius: 20px;
}

.modal-page h5 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 30px;
  margin: 0;
  margin-top: -5%;
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 5%;
}

.modal-action .actions-btn {
    float: right;
    margin-top: 1rem;
}
.modal-action .actions-btn div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.modal-action .link {
  float: left;
  margin-top: 1rem;
  margin-bottom: 4rem;
}

.modal-action .link a{
  color: #F8CE6F;
  font-size: 20px;
  font-weight: 500;
}




@media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }
  
}
@media (max-width: 768px) {
  .title{
    width: 90px;
    font-size: 28px;
    text-align: center;
    line-height: 2rem;
  }

  
  .header{
    gap: 1rem;
  }

  .modal-action{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  

  .modal{
    margin-left: 1%;
  }

  .input-group label{
    font-size: 16px;
  }
  .input-group input{
    padding: 10px;
    font-size: 14px;
  }
  .input-group textarea{
    font-size: 14px;
  }
  .modal-action .link a{
    font-size: 16px;
  }

  .card{
    gap: 1rem;
    width: 100%;
  }

  .card-info h4{
    font-size: 20px;
    margin-bottom: 0;
  }

  .card-info .card-desc{
    margin-bottom: 0;
    font-size: 16px;
  }
  .card-info p{
    font-size: 17px;
  }

  .icons-card{
    gap: 0.1rem;
  }
  .icons-card .icon{
    padding: 0.1rem;
  }
  .icons-card .icon button img{
    width: 20px;
    height: 20px;
  }

  
}


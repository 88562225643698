.footer {
  display: flex;
  text-align: center;
  font-size: 24px;
  font-weight: 200;
  justify-content: center;
  align-items: center;
  background-color: #ecf5f2;
  padding: 2rem;
}

@media (max-width: 768px) {
  .footer {
    font-size: 16px;
    padding: 1rem;
  }
}
